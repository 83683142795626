<template>
  <v-container fluid>
    <v-row class="page-wrapper">
      <v-col cols="12" md="6" lg="7" class="banner-wrapper p-0">
        <video-background
          :src="
            isSpl
              ? require('../../../../../public/media/video/spl.mp4')
              : require('../../../../../public/media/video/login-bg-3.mp4')
          "
          style="height: 100vh"
          :poster="
            isSpl ? null : require('../../../../../public/img/login-bg-img.png')
          "
        >
          <div
            class="d-flex flex-column justify-content-between"
            style="height: 75%"
          >
            <img
              class="img-fluid rounded-lg mt-7 ml-10 iq-purple-logo"
              :class="{
                'mt-7 mr-10': isRTL,
                'mt-7 ml-10': !isRTL,
              }"
              :src="platformLogo.source"
              :width="platformLogo.width"
              alt="logo"
            />
            <div v-if="!isSpl" class="text-center">
              <img
                src="/img/powering-ecommerce.png"
                alt="powering-ecommerce"
                class="p-2 w-300px w-sm-400px w-md-450px"
              />
            </div>
          </div>
        </video-background>
      </v-col>

      <v-col
        cols="12"
        md="6"
        lg="5"
        :class="[
          'overflow-auto d-flex flex-column justify-content-between poppins h-100',
          isSpl ? 'container-wrapper-spl-right' : 'container-wrapper-iq-right',
        ]"
      >
        <div
          class="d-flex justify-content-center align-items-center flex-grow-1 mt-lg-0 signin-container-wrapper"
        >
          <div
            class="signin-container w-325px w-lg-400px w-md-50 px-3 px-sm-0"
            :class="{ 'text-right': isRTL }"
          >
            <div class="font-weight-boldest mb-12">
              <h1 class="font-weight-boldest title mb-3 mt-15 mt-lg-0">
                {{ $t("log_in") }}
              </h1>
              <p class="subtitle">
                {{ $t("run_manage_and_scale_your_ecommerce") }}
              </p>
            </div>
            <b-form @submit="signinUser">
              <div class="mt-10">
                <b-input-group class="input-group">
                  <b-input-group-append>
                    <div class="mt-5 ml-3" :class="{ 'mr-3': isRTL }">
                      <span class="svg-icon">
                        <v-icon size="28">mdi-account-outline</v-icon>
                      </span>
                    </div>
                  </b-input-group-append>
                  <b-form-input
                    :placeholder="$t('username')"
                    required
                    v-model="form.email"
                    name="email"
                    type="text"
                    id="email"
                    class="email-input"
                    ref="email"
                  ></b-form-input>
                </b-input-group>
              </div>
              <div class="mt-10">
                <b-input-group class="input-group">
                  <b-input-group-append>
                    <div class="mt-5 mt-lg-6 ml-3" :class="{ 'ml-5': isRTL }">
                      <span class="svg-icon">
                        <v-icon size="24">mdi-lock</v-icon>
                      </span>
                    </div>
                  </b-input-group-append>
                  <b-form-input
                    required
                    v-model="form.password"
                    :type="passwordHidden ? 'password' : 'text'"
                    name="password"
                    id="password"
                    class="password-input"
                    ref="password"
                    :placeholder="$t('password')"
                  ></b-form-input>
                  <b-input-group-prepend
                    @click="passwordHidden = !passwordHidden"
                    class="password-prepend"
                  >
                    <div
                      class="mt-5 mt-lg-mt-6 mr-5"
                      :class="{ 'ml-5': isRTL }"
                    >
                      <span class="svg-icon">
                        <v-icon size="20"
                          >mdi-{{
                            passwordHidden ? "eye-off-outline" : "eye-outline"
                          }}</v-icon
                        >
                      </span>
                    </div>
                  </b-input-group-prepend>
                </b-input-group>
                <div class="text-white font-size-lg mt-6">
                  <router-link class="link-white" to="/forgot_password">{{
                    $t("forgot_password")
                  }}</router-link>
                </div>
              </div>
              <div
                class="d-flex submit-button-container justify-content-center mb-14 mt-14 p-1"
              >
                <button
                  type="submit"
                  ref="submit"
                  class="submit-button m-1 font-weight-bolder"
                  :class="[
                    !isSubmitButtonClickable ? 'disabled' : '',
                    loading ? ' loading' : '',
                    isSubmitButtonClickable && !loading ? '' : '',
                  ]"
                >
                  <template v-if="loading">
                    {{ $t("logging_in") }}
                    <v-progress-circular
                      :size="20"
                      width="3"
                      color="primary"
                      indeterminate
                      class="ml-2 mb-1"
                    ></v-progress-circular>
                  </template>
                  <template v-else>{{ $t("login") }}</template>
                </button>
              </div>
            </b-form>
          </div>
        </div>
        <div
          v-if="!isSpl"
          class="d-flex justify-content-center text-white mb-3 mt-14 ml-3 ml-md-0"
        >
          <div>
            <img
              src="/img/IQ-Hybrid-white.png"
              alt="logo"
              class="img-fluid w-85px mr-2 mb-2"
            />
            <span class="font-size-h6"
              ><span>{{ currentYear }} </span><span>©</span> -
              <span>{{ $t("all_rights_reserved") }}</span></span
            >
          </div>
        </div>
        <div
          v-else
          class="d-flex justify-content-center text-white mb-3 mt-14 ml-3 ml-md-0"
        >
          <div>
            <img src="/img/spl.png" alt="logo" class="img-fluid w-55px mr-2" />
            <span class="font-size-h4 mx-1">{{ $t("powered_by") }}</span>
            <img
              src="https://api.iqhybrid.com/images/logo/iqh-light.svg"
              alt="hybrid-logo"
              class="img-fluid w-85px mx-2 mb-2"
            />
            <!--            <span class="font-size-h4">with</span>-->

            <!--            <img-->
            <!--              src="https://api.iqhybrid.com/images/heart-light.svg"-->
            <!--              alt="love-icon"-->
            <!--              class="img-fluid w-20px mx-3 mb-2"-->
            <!--            />-->
            <div class="font-size-h6 px-1 mt-2 text-center">
              <span>{{ currentYear }} </span><span>©</span> -
              <span>{{ $t("all_rights_reserved") }}</span>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <router-link to="/privacy_policy" class="text-white">{{
            $t("privacy_policy")
          }}</router-link>

          <router-link class="ml-4 text-white" to="/terms_and_conditions"
            >{{ $t("terms") }} {{ $t("and_conditions") }}</router-link
          >
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VideoBackground from "vue-responsive-video-background-player";
import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";
import i18nService from "@/core/services/i18n.service";
import { MAKE_DEFAULT_HAS_THEME_SET } from "@/core/services/store/main.module";

export default {
  name: "Login",
  components: {
    VideoBackground,
  },
  data: () => ({
    form: {
      email: "",
      password: "",
    },
    loading: false,
    passwordHidden: true,
    currentYear: new Date().getFullYear(),
  }),
  computed: {
    isRTL() {
      return i18nService.isRTL();
    },
    isSubmitButtonClickable: function () {
      return this.isPasswordValid;
      // return this.isEmailValid && this.isPasswordValid;
    },

    // isEmailValid: function () {
    //   const reg = RegExp(
    //     // eslint-disable-next-line
    //     /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
    //   );
    //   return reg.test(this.form.email);
    // },

    isPasswordValid: function () {
      return this.form.password.length !== 0;
    },
    isSpl: function () {
      const fullPath = window.location.hostname;
      const spl = "spl.iqhybrid.com";

      if (fullPath === spl) {
        return true;
      }
      return false;
    },
    platformLogo: function () {
      if (this.isSpl) {
        return {
          source: "/img/spl.png",
          width: "80px",
        };
      }
      return {
        source: "/img/iqplatform.png",
        width: "57px",
      };
    },
  },
  methods: {
    signinUser(event) {
      event.preventDefault();
      if (this.isSubmitButtonClickable && !this.loading) {
        let email = this.form.email;
        let password = this.form.password;

        // clear existing errors
        this.$store.dispatch(LOGOUT);

        // set spinner to submit button
        this.loading = true;

        // send login request
        this.$store
          .dispatch(LOGIN, { email, password })
          // go to which page after successfully login
          .then((data) => {
            if (data.success === false) {
              return;
            } else if (data.success === true) {
              this.$store.commit(MAKE_DEFAULT_HAS_THEME_SET, 0);
              this.$router.push({ name: "dashboard.dashboardDelivery" });
            }
          })
          .catch(() => {})
          .finally(() => {
            setTimeout(() => {
              this.loading = false;
            }, 2000);
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/pages/login/login.scss";
</style>
